import React from "react";
import PropTypes from "prop-types"
import {graphql, StaticQuery} from "gatsby";
import { FaShareAlt } from 'react-icons/fa';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
} from 'react-share';


const Sharebuttons = () => (
    <StaticQuery
        query={graphql`
          query SiteUrlQuery {
            site {
              siteMetadata {
                siteUrl
                title
              }
            }
          }
        `}
        render={data => (
            <>
                <div className="share-buttons">
                <FacebookShareButton
                    url={data.site.siteMetadata.siteUrl}
                    quote={data.site.siteMetadata.title}
                >
                <FacebookIcon size={30} />
                </FacebookShareButton>

                <TwitterShareButton
                    url={data.site.siteMetadata.siteUrl}
                    quote={data.site.siteMetadata.title}
                >
                <TwitterIcon size={30} />
                </TwitterShareButton>

                <LinkedinShareButton
                    url={data.site.siteMetadata.siteUrl}
                    quote={data.site.siteMetadata.title}
                >
                <LinkedinIcon size={30} />
                </LinkedinShareButton>
                </div>
            </>
        )}
    />
)

Sharebuttons.defaultProps = {
    siteUrl: ``,
    title: ``
}

Sharebuttons.propTypes = {
    siteUrl: PropTypes.string,
    title: PropTypes.string
}


class Sharetoggle extends React.Component {
    constructor (props, context) {
        super(props, context)
        this.state = {
            isHidden: true
        }
    }
    toggleHidden () {
        this.setState({
            isHidden: !this.state.isHidden
        })
    }
    render () {
        return (
            <div>
                <div className="share-button" onClick={this.toggleHidden.bind(this)} >
                    <span className="icon">
                       <FaShareAlt size={30} color="white" />
                    </span>
                </div>
                {!this.state.isHidden && <Sharebuttons />}
            </div>
        )
    }
}

export default Sharetoggle;

